<template>
<div>
  <button @click="add">点击增加</button>
</div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    add() {
      this.$store.commit('add')
      console.log(this.$store.state.count)
    }
  }
}
</script>

<style></style>
